import imageGallery1 from './Screenshot1.png';
import imageGallery2 from './Screenshot2.png'
import imageGallery3 from './Screenshot3.png'
import imageGallery4 from './Screenshot4.png'
import imageGallery5 from './Screenshot5.png'
import imageGallery6 from './Screenshot6.png'
import imageGallery7 from './Screenshot7.png'
import imageGallery8 from './Screenshot8.png'
import imageGallery9 from './Screenshot9.png'
import imageGallery10 from './Screenshot10.png'
import imageGallery11 from './Screenshot11.png'
import imageGallery12 from './Screenshot12.png'
import imageGallery13 from './Screenshot13.png'
import imageGallery14 from './Screenshot14.png'
import imageGallery15 from './Screenshot15.png'
import imageGallery16 from './Screenshot16.png'
import imageGallery17 from './Screenshot17.png'
import imageGallery18 from './Screenshot18.png'
import imageGallery19 from './Screenshot19.png'
import imageGallery20 from './Screenshot20.png'
import imageGallery21 from './Screenshot21.png'
import imageGallery22 from './Screenshot22.png'
import imageGallery23 from './Screenshot23.png'
import imageGallery24 from './Screenshot24.png'
import imageGallery25 from './Screenshot25.png'
import imageGallery26 from './Screenshot26.png'

export const GALLERY_IMAGES_ARRAY: string[] = [
  imageGallery26, imageGallery25, imageGallery24, imageGallery23, imageGallery22, imageGallery21, imageGallery20, imageGallery19, imageGallery18, imageGallery17, imageGallery16, imageGallery15, imageGallery14, imageGallery13, imageGallery12, imageGallery11, imageGallery10, imageGallery9, imageGallery8, imageGallery7, imageGallery6, imageGallery5, imageGallery4, imageGallery3, imageGallery2, imageGallery1
];

